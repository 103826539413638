import React, { useEffect } from 'react'
import Footer from '../Compliance/Footer/index'
import Header from './Header/index'
import Info from './Info'

const HomeResource = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Header />
      <Info />
      <Footer />
    </div>
  )
}

export default HomeResource
