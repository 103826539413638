import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import Moment from 'react-moment'
import { useStore } from '../../../../mobx-store/context'
import { getString } from '../../../Tracking/Right/right'
import { List as ListWrap, ListItem } from './style'

const List = observer(() => {
  const store = useStore()

  useEffect(() => {
    if (
      !store.jurs?.length &&
      !store.topics?.length &&
      !store.lineOfBusinesses?.length
    )
      store.loadSelect('companyOptions')
  }, [])

  const accountType = {
    admin: 'Team Admin',
    compliance_owner: 'Compliance Owner',
    member: 'Standard Team Member',
    owner: 'Owner',
  }

  const listItems = [
    {
      key: 0,
      title: 'Account Type:',
      value: accountType[store.user[0]?.member_type] || '',
    },
    {
      key: 1,
      title: 'Join Date:',
      value: (
        <Moment format='MM/DD/YYYY'>{store.user[0]?.date_joined ?? ''}</Moment>
      ),
    },
    {
      key: 2,
      title: 'Business Name:',
      value: store.company[0].name,
    },
    {
      key: 3,
      title: 'My Jurisdictions:',
      value: getString(store.jurs, store.user[0]?.jurisdictions ?? []),
      hide: !store.company[0]?.have_access_to_tracking,
    },
    {
      key: 4,
      title: 'My Topics:',
      value: getString(store.topics, store.user[0]?.topics ?? []),
      hide: !store.company[0]?.have_access_to_tracking,
    },
    {
      key: 5,
      title: 'My Lines of Business:',
      value: getString(
        store.lineOfBusinesses,
        store.user[0]?.line_of_business ?? []
      ),
      hide: !store.company[0]?.is_premium,
    },
  ]

  return (
    <ListWrap>
      {listItems.map(
        (item) =>
          !item.hide && (
            <ListItem key={item.key}>
              {item.title} <span>{item.value || 'None'}</span>
            </ListItem>
          )
      )}
    </ListWrap>
  )
})

export default List
